import React from 'react';
import PropTypes from 'prop-types';

const { node, string } = PropTypes;

const ServicesPageSectionGraphic = ({ alt, src, ...rest }) => (
  <div
    css={{
      marginBottom: 40,
      textAlign: 'center',
      '@media (min-width: 768px)': {
        marginBottom: 0,
        marginRight: 40,
        minWidth: 200,
        maxWidth: 200,
      },
    }}
    {...rest}
  >
    <img
      alt={alt}
      css={{
        maxWidth: 200,
        '@media (min-width: 768px)': {
          maxWidth: '100%',
        },
      }}
      src={src}
    />
  </div>
);

ServicesPageSectionGraphic.propTypes = {
  alt: string.isRequired,
  src: node.isRequired,
};

export default ServicesPageSectionGraphic;
