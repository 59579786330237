import React from 'react';

import PageContent from '../components/page-content';
import PageContentTitle from '../components/page-content-title';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import CaseStudy from '../components/case-studies/case-study';

const CaseStudiesPage = () => (
  <Layout>
    <SEO title="Case Studies" />
    <PageContent>
      <PageContentTitle>Case Studies</PageContentTitle>
      <div>
        <CaseStudy>
          <h2>Client: Independent School District with over 250 employees</h2>
          <h3>Services:</h3>
          <ul>
            <li>Professional Development</li>
            <li>Building content knowledge for English language Arts (ELAR)</li>
            <li>Designed components of Professional Development programs related to curriculum</li>
            <li>Student/Teacher engagement</li>
            <li>Data Item Analysis with planning and instruction</li>
            <li>
              Support development of teacher of teachers throughout teachers&rsquo; institutes,
              observations, reflections, and in-class modeling/mentoring
            </li>
          </ul>
          <h3>Results:</h3>
          <ul>
            <li>Teacher and student compounded growth achievement</li>
            <li>Built new levels of sustainability</li>
            <li>
              The Texas Education Accountability System increased from 2017-2018 with one
              distinction to five out of five in the elementary grades
            </li>
          </ul>
        </CaseStudy>

        <CaseStudy>
          <h2>Client: Technology Solutions Provider with 95 employees</h2>
          <h3>Services:</h3>
          <ul>
            <li>Professional Development</li>
            <li>The 10 by 10 Daily Net New Prospecting Strategy</li>
            <li>
              <a href="https://www.askburt.biz/art-of-professional-prospecting/">
                <em>The Art of Professional Prospecting</em>
              </a>{' '}
              and Sales Training
            </li>
            <li>
              AskBurt<sup>tm</sup> mobile sales app implementation
            </li>
            <li>Role play scenarios</li>
            <li>The 8 Strategies to successful client retention</li>
            <li>Tailored BURTISMS to business needs</li>
            <li>Productivity Evaluation website surveys</li>
          </ul>
          <h3>Results:</h3>
          <ul>
            <li>Supported committed Sales Warriors to meet and exceed 6 figure incomes</li>
            <li>
              Increased preparation and focus on Influencer and C Level appointments with AskBurt
              <sup>tm</sup> mobile sales app
            </li>
            <li>
              Successfully prepared Sales Warriors for tough calls through role play scenarios
            </li>
            <li>Raised sales manager expectations through one on one coaching and mentoring</li>
            <li>
              Identified potential desired and undesired sales candidates through AskBurt
              <sup>tm</sup> productivity and sales surveys
            </li>
          </ul>
        </CaseStudy>

        <CaseStudy>
          <h2>Client: Government with over 5,000 employees</h2>
          <h3>Services:</h3>
          <ul>
            <li>Professional Development</li>
            <li>Manager PD engagement</li>
            <li>Core Values Employee Implementation</li>
            <li>Value Proposition created by business line</li>
            <li>The Pentagon Leadership Series</li>
            <li>Employee Survey results focus by business line</li>
            <li>SMART Goal implementations</li>
            <li>Crucial Conversations and Role Play Scenarios</li>
            <li>The 52 Strategic Words and Leadership Moments</li>
            <li>Created concept for monthly employee newsletter</li>
            <li>Skillset and Productivity Survey</li>
          </ul>
          <h3>Results:</h3>
          <ul>
            <li>
              Supported the improved overall employee survey results by 33% with the highest
              improved manager once we became a consulting partner
            </li>
            <li>
              Contributed to increased employee engagement satisfaction with management by 16% with
              highest improved manager once we became a consulting partner
            </li>
            <li>
              Successfully developed managers to build relationships, conduct effective meetings,
              increased engagement, accountability and productivity
            </li>
            <li>
              Through (3rd party) survey results we identified and improved areas including trust,
              transparency, communication and effectiveness
            </li>
          </ul>
        </CaseStudy>

        <CaseStudy>
          <h2>Client: Medical Testing Group with 60 employees</h2>
          <h3>Services:</h3>
          <ul>
            <li>Professional Development</li>
            <li>The 10 by 10 Daily Net New Prospecting Strategy</li>
            <li>
              <a href="https://www.askburt.biz/art-of-professional-prospecting/">
                <em>The Art of Professional Prospecting</em>
              </a>{' '}
              and Sales Training
            </li>
            <li>
              AskBurt<sup>tm</sup> mobile sales app implementation
            </li>
          </ul>
          <h3>Results:</h3>
          <ul>
            <li>
              Successfully downsized sales organization while increasing client footprint in the
              highly competitive medical services arena.
            </li>
            <li>
              Increased sales representatives performance and focus with the AskBurt<sup>tm</sup>{' '}
              Mobile Sales app
            </li>
            <li>
              Raised director of sales&rsquo; expectations on potential new hires throughout
              one-on-one coaching and mentoring
            </li>
          </ul>
        </CaseStudy>
      </div>
    </PageContent>
  </Layout>
);

export default CaseStudiesPage;
