import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const LeadershipAndCommunicationPage = () => (
  <Layout>
    <SEO title="Leadership and Communication" />
    <PageContent>
      <PageContentTitle>Leadership and Communication</PageContentTitle>
      <div
        css={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p>
          Leader,
          <br />
          Imagine:
          <br />
          Senior Leadership without Communication.
          <br />
          Core Values without Communication.
          <br />
          Value Propositions without Communication.
          <br />
          Your Direct Reports without Communication.
          <br />
        </p>
        <p>
          The backbone of yesterday and today’s most successful results, is Communication. Without
          it, initiatives fail to launch and visionary goals are simply not accomplished.
        </p>
        <p>
          Leader, in the competitive arenas we engage in daily, Communication combined with
          developed skill sets, are two of the very necessary foundations required in order to
          build Your successful teams and sustainable legacy&rsquo;s.
        </p>
        <p>Ask Yourself:</p>
        <ul>
          <li>
            1. When was the last time I gathered the team to specifically discuss the importance of
            Communication?
          </li>
          <li>
            2. How do I currently rank my individual team members as to their Communication skills
            and with whom will I immediately begin reconstruction?
          </li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default LeadershipAndCommunicationPage;
