import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const LeadershipAndAccountabilityPage = () => (
  <Layout>
    <SEO title="Leadership and Accountability" />
    <PageContent>
      <PageContentTitle>Leadership and Accountability</PageContentTitle>
      <div
        css={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p>
          Leader,
          <br />
          Accountability...a word which is often present in an organization&rsquo;s core values,
          mission statements, value propositions, meeting discussions and sadly, employee
          termination records.
          <br />
          Clearly, depending on the narrative before or after, the word Accountability can impact
          an organization and it’s members positively, as well as negatively.
          <br />
          Leader, the purpose of today&rsquo;s leadership moment, is to have resonate with you
          that;
          <br />
          People will often win or fail their circumstances depending on whether they do or do not
          bring themselves to accept the Accountable side of their story. (Connor, Smith, Hickman
          2015).
          <br />
          Ask Yourself:
        </p>
        <ul>
          <li>
            1. What might be the current Accountability issues my team is facing, as a result of
            one or a few members?
          </li>
          <li>
            2. How am I addressing the Accountability gaps HEAD ON, in order to avoid the
            consequential costs of members currently not on the favorable side of Accountability?
          </li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default LeadershipAndAccountabilityPage;
