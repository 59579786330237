import React from 'react';

import maxWellLogo from '../images/maxwell-logo.png';

const MaxwellLockup = props => {
  return (
    <div
      css={{
        maxWidth: 220,
        marginLeft: 0,
        marginRight: 'auto',
        marginBottom: 16,
      }}
      {...props}
    >
      <hr
        css={{
          background: '#D8D8D8',
          border: 'none',
          height: 1,
          marginBottom: 16,
        }}
      />
      <img css={{ maxWidth: '100%' }} src={maxWellLogo} alt="John Maxwell" />
    </div>
  );
};

export default MaxwellLockup;
