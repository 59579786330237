import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const LeadershipAndLoyaltyPage = () => (
  <Layout>
    <SEO title="Leadership and LOYALTY" />
    <PageContent>
      <PageContentTitle>Leadership and LOYALTY</PageContentTitle>
      <div
        css={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p>
          Leader,
          <br />
          There exists more than one source which claims origin to the word, LOYALTY.
          <br />
          History ultimately takes us back to the Latin interpretation of LEX. This means LAW.
          <br />
          Loyalty to You and your vision should not come as a result of, your Law.
          <br />
          In today&rsquo;s competitive business environments, Loyalty from your senior management
          and direct reports should be built on a desired, optional, willing spirit of eagerness to
          follow the paths you consistently carve and fully lead.
          <br />
          Loyalty, is built in part by your earned successes from purposeful leadership, influence,
          fairness, competency, transparency and humbleness.
          <br />
          Ask Yourself:
          <br />
        </p>
        <ul>
          <li>
            1. What actions did I exhibit today in order that I may have earned positive Loyalty?
          </li>
          <li>2. How do I develop Loyalty from those who presently are Disloyal?</li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default LeadershipAndLoyaltyPage;
