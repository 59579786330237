import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const Testimonial = ({ children, ...rest }) => (
  <div
    css={{
      marginBottom: 16,
      maxWidth: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderBottom: '1px solid #ced4da',
      paddingBottom: 16,
      ':last-of-type': {
        paddingBottom: 0,
        marginBottom: 0,
        borderBottom: 'none',
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

Testimonial.propTypes = {
  children: node.isRequired,
};

export default Testimonial;
