import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const ContactPage = () => (
  <Layout>
    <SEO title="Message Sent" />
    <PageContent>
      <PageContentTitle>Message Sent</PageContentTitle>
      <p css={{ textAlign: 'center' }}>We will respond as soon as possible.</p>
    </PageContent>
  </Layout>
);

export default ContactPage;
