import React from 'react';
import { Link } from 'gatsby';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const ContactPage = () => (
  <Layout>
    <SEO title="Error" />
    <PageContent>
      <PageContentTitle>Error</PageContentTitle>
      <div css={{ textAlign: 'center' }}>
        <p css={{ color: 'red' }}>There was an error sending your message.</p>
        <Link to="/contact" css={{ color: '#4F9CF2' }}>
          Please try again
        </Link>
      </div>
    </PageContent>
  </Layout>
);

export default ContactPage;
