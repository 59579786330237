import React from 'react';
import { node } from 'prop-types';

const Page = ({ children, ...rest }) => (
  <main
    css={{
      fontSize: '0.9rem',
      marginBottom: 80,
    }}
    {...rest}
  >
    {children}
  </main>
);

Page.propTypes = {
  children: node.isRequired,
};

export default Page;
