import React from 'react';

import PageContent from '../components/page-content';
import PageContentTitle from '../components/page-content-title';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const ResourcesPage = () => (
  <Layout>
    <SEO title="Resources" />
    <PageContent>
      <PageContentTitle>Resources</PageContentTitle>
      <div
        css={{
          maxWidth: 560,
          marginLeft: 'auto',
          marginRight: 'auto',
          a: {
            color: '#4F9CF2',
          },
          h2: {
            fontWeight: 700,
            fontSize: 22,
            marginBottom: 15,
          },
          ul: {
            listStyle: 'disc',
            marginLeft: 30,
            marginBottom: 16,
          },
        }}
      >
        <h2>Books</h2>
        <ul>
          <li>
            <a href="https://www.52strategicwords.com/">
              <em>The 52 Strategic Words</em>
            </a>
          </li>
          <li>
            <a href="https://www.askburt.biz/art-of-professional-prospecting/">
              <em>The Art of Professional Prospecting</em>
            </a>
          </li>
        </ul>

        <h2>Employee Productivity Evaluations &amp; Surveys</h2>
        <ul>
          <li>
            <a href="https://www.askburt.biz/ten-by-ten-survey/">Ten by Ten Survey</a>
          </li>
          <li>
            <a href="https://www.askburt.biz/productivity-evaluations/selling/">
              Selling Experience - Productivity Evaluation
            </a>
          </li>
          <li>
            <a href="https://www.askburt.biz/productivity-evaluations/millennial/">
              No Selling Experience - Productivity Evaluation
            </a>
          </li>
          <li>
            <a href="https://www.askburt.biz/bcit-employee-survey/">Technology Support Survey</a>
          </li>
          <li>
            <a href="https://www.askburt.biz/senior-consultant-survey/">
              Senior Consultant Survey
            </a>
          </li>
        </ul>

        <h2>
          AskBurt<sup>tm</sup> Resources
        </h2>
        <ul>
          <li>
            <a href="https://www.askburt.biz/ten-by-ten/">Ten By Ten Form (PDF)</a>
          </li>
          <li>
            <a href="https://www.askburt.biz/7-components/">
              ​7 Components of Burt&rsquo;s Ten By Ten Net New Prospecting Strategy (PDF)
            </a>
          </li>
          <li>
            <a href="https://www.askburt.biz/graph/">Net New Prospecting Illustration</a>
          </li>
          <li>
            <a href="https://www.askburt.biz/warrior-assessment/">Sales Warrior Assessment Form</a>
          </li>
          <li>
            <a href="https://www.askburt.biz/20-30-rule/">20/30 Rule</a>
          </li>
          <li>
            <a href="https://www.askburt.biz/burtisms/">BURTISMS</a>
          </li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default ResourcesPage;
