import React, { useState } from 'react';

import PageContent from '../components/page-content';
import PageContentTitle from '../components/page-content-title';
import FormControl from '../components/forms/form-control';
import FormGroup from '../components/forms/form-group';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const ContactPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Layout>
      <SEO title="Contact" />
      <PageContent>
        <PageContentTitle>Contact Us</PageContentTitle>
        <form
          css={{
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
            label: {
              display: 'none',
            },
          }}
          method="post"
          encType="multipart/form-data"
          action="https://3bo9vpfe94.execute-api.us-east-1.amazonaws.com/p/"
          id="contactForm"
          onSubmit={() => {
            setIsSubmitting(true);
          }}
        >
          <FormGroup>
            <label htmlFor="form_name">Name</label>
            <FormControl
              is="input"
              id="form_name"
              type="text"
              name="name"
              placeholder="Name"
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="form_email">Email</label>
            <FormControl
              is="input"
              id="form_email"
              type="email"
              name="email"
              placeholder="Email"
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="form_email">Email</label>
            <FormControl
              is="textarea"
              id="form_message"
              name="message"
              placeholder="Message"
              rows="4"
              required
            />
          </FormGroup>
          <input
            name="redirectLocation"
            type="hidden"
            css={{ display: 'none' }}
            value="https://baabco.biz/contact/thanks"
          />
          <input
            name="errorLocation"
            type="hidden"
            css={{ display: 'none' }}
            value="https://baabco.biz/contact/error"
          />

          <FormGroup>
            <input
              css={{
                display: 'inline-block',
                fontWeight: 400,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'middle',
                userSelect: 'none',
                border: '1px solid transparent',
                fontSize: '1rem',
                lineHeight: 1.5,
                backgroundColor: '#4f9cf2',
                borderRadius: 50,
                padding: '5px 15px',
                color: '#fff',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,.1)',
                ':disabled': {
                  opacity: 0.5,
                },
              }}
              disabled={isSubmitting}
              type="submit"
              value="Send Message"
            />
          </FormGroup>
        </form>
      </PageContent>
    </Layout>
  );
};

export default ContactPage;
