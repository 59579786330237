import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const PageContentColumn = ({ children, ...rest }) => (
  <div
    css={{
      marginBottom: 40,
      p: {
        marginBottom: 15,
      },
      '@media (min-width: 768px)': {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '45%',
        marginBottom: 0,
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

PageContentColumn.propTypes = {
  children: node.isRequired,
};

export default PageContentColumn;
