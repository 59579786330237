import React from 'react';

import PageContent from '../components/page-content';
import PageContentTitle from '../components/page-content-title';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import Testimonial from '../components/testimonials/testimonial';
import TestimonialContent from '../components/testimonials/testimonial-content';

const TestimonialsPage = () => (
  <Layout>
    <SEO title="Testimonials" />
    <PageContent>
      <PageContentTitle>Testimonials</PageContentTitle>
      <Testimonial>
        <TestimonialContent>
          <p>
            Alicia, thank you for today’s PD. It was on target and just what the doctor ordered!
          </p>
        </TestimonialContent>
        <p>
          Susan D.
          <br />
          Public School District
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            The AskBurt Sales app is so easy to use, as it classifies the various scenarios you may
            have questions about, and provides a solid answer or talk-track for you to use. I
            visualize this being used right before a sales call, perhaps if you are going it alone,
            and or cannot reach your manager for direction.
          </p>
        </TestimonialContent>
        <p>
          Joe S.
          <br />
          Sales Team Lead
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            Jane, regarding Alicia and Burt, I am certain you will experience their professional
            services as an essential component to your leadership growth.
          </p>
        </TestimonialContent>
        <p>
          Michael T.
          <br />
          Chief Information Officer
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            Alicia, the Leadership Moments questions on Listening were especially relavent to me
            this morning. I intend on incorporating them with my supervisors at this week’s
            meeting.
          </p>
        </TestimonialContent>
        <p>
          Tricia V.
          <br />
          Business Unit Manager
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>We moved the needle today. You challenged us. Thank you!</p>
        </TestimonialContent>
        <p>
          Barbara F.
          <br />
          Public School District
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            Your strategies are simple, sensible and vital for our new hires to embrace and make it
            part of their sales day DNA.
          </p>
        </TestimonialContent>
        <p>
          Steve L.
          <br />
          Vice President of Business Development
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            The senior leadership sees the difference every time you share your wisdom with our
            managers.
          </p>
        </TestimonialContent>
        <p>
          Carlos G.
          <br />
          Government
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            It only takes a spark to ignite a sales career, The Art of Professional Prospecting is
            full of them. Can you find the one that will transform your potential?
          </p>
        </TestimonialContent>
        <p>
          Bill P.
          <br />
          Co-President
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>Thank you so much for peeling the many layers on our data. You are the IT Factor!</p>
        </TestimonialContent>
        <p>
          Christine D.
          <br />
          Public School District
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            I sincerely thank you for sharing your wonderful recommendations to help our reps
            achieve greater success in closing deals.
          </p>

          <p>
            We&rsquo;ve implemented your ten by ten strategy, and we&rsquo;ve quickly seen our
            sales pipelines increase significantly! It&rsquo;s important to have a solid structure,
            and with the tools that you&rsquo;ve provided, we&rsquo;re now able to leverage our
            sales abilities and work more efficiently. Thank you for all that you do!
          </p>
        </TestimonialContent>
        <p>
          Raj P.
          <br />
          Senior Document Management Specialist
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            &ldquo;Thank you. You are amazing! I have learned so much from you. We are beyond
            thankful.
          </p>
        </TestimonialContent>
        <p>
          Kristine S.
          <br />
          Government
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          <p>
            ...as a senior sales rep I firmly believe in Burt&rsquo;s 10 by 10. It gives me daily
            structure for prospecting. It keeps my pipeline healthy while managing my existing
            accounts and doesn’t let me &ldquo;forget&rdquo; to prospect. I recommend this strategy
            to any senior rep or rookie trying to build his business.
          </p>
        </TestimonialContent>
        <p>
          Colin W.
          <br />
          Senior Sales Consultant
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          It&rsquo;s evident that the fruits of your labor helped create a much needed shift this
          week... grade 5 and 6 classrooms looked GOOD and grade 3 classrooms looked great! Thank
          YOU!!!!!!!!!!!!!!
        </TestimonialContent>
        <p>
          Teacher.
          <br />
          Public School District
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          I&rsquo;m so grateful to work with an organization that invests in their managers to help
          us become better at what we are tasked to do.
        </TestimonialContent>
        <p>
          Tommy B.
          <br />
          Government
        </p>
      </Testimonial>

      <Testimonial>
        <TestimonialContent>
          Burt, thank you for the feedback from our one on one last week. We had a good session
          today with Dr. Alicia.
        </TestimonialContent>
        <p>
          Aron F.
          <br />
          Government
        </p>
      </Testimonial>
    </PageContent>
  </Layout>
);

export default TestimonialsPage;
