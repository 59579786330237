import { Link } from 'gatsby';
import React, { useState } from 'react';

import BaabcoLogo from '../images/baabco-logo.svg';

const BREAKPOINT = 870;

const NavLink = ({ children, ...rest }) => (
  <Link
    css={{
      textDecoration: 'none',
      fontWeight: 'bold',
      display: 'block',
      color: '#FFF',
      padding: '10px 0',
      fontSize: '1.1rem',
      [`@media (min-width: ${BREAKPOINT + 1}px)`]: {
        color: '#142037',
        fontSize: '0.9rem',
        padding: '0 5px',
        margin: '0 5px',
      },
      ':hover': {
        color: '#4F9CF2',
      },
    }}
    {...rest}
  >
    {children}
  </Link>
);

const NavLinkButton = ({ children, ...rest }) => (
  <Link
    css={{
      backgroundColor: '#4F9CF2',
      borderRadius: 50,
      padding: '5px 15px',
      color: '#FFF',
      textDecoration: 'none',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
      ':hover': {
        color: '#FFF',
      },
    }}
    {...rest}
  >
    {children}
  </Link>
);

const HamburgerMenuBar = ({ isOpen }) => (
  <span
    css={{
      display: 'block',
      position: 'absolute',
      height: 3,
      borderRadius: 1,
      width: '100%',
      background: '#142037',
      opacity: 1,
      left: 0,
      transform: 'rotate(0deg)',
      transition: '.25s ease-in-out',
      ':nth-of-type(1)': {
        top: 0,
      },
      ':nth-of-type(2)': {
        top: 6,
      },
      ':nth-of-type(3)': {
        top: 6,
      },
      ':nth-of-type(4)': {
        top: 12,
      },
      ...(isOpen
        ? {
            background: '#D8D8D8',
            ':nth-of-type(1)': {
              top: 9,
              width: '0%',
              left: '50%',
            },
            ':nth-of-type(2)': {
              transform: 'rotate(45deg)',
            },
            ':nth-of-type(3)': {
              transform: 'rotate(-45deg)',
            },
            ':nth-of-type(4)': {
              top: 9,
              width: '0%',
              left: '50%',
            },
          }
        : {}),
    }}
  />
);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        '@media (min-width: 670px)': {
          marginBottom: 40,
        },
      }}
    >
      <div css={{ maxWidth: 220 }}>
        <a href="/">
          <img css={{ maxWidth: '100%' }} src={BaabcoLogo} alt="BAABCO Consulting Services" />
        </a>
      </div>
      <div css={{ marginLeft: 'auto' }}>
        <nav
          css={{
            transition: 'all 200ms cubic-bezier(0.860, 0.000, 0.070, 1.000)',
            display: 'flex',
            alignItems: 'center',
            [`@media (max-width: ${BREAKPOINT}px)`]: {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              zIndex: 900,
              boxSizing: 'border-box',
              backgroundColor: '#142037',
              height: '100vh',
              paddingBottom: 60,
              paddingTop: 30,
              transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <NavLink to="/" className="baabco-navbar__nav__link">
            Home
          </NavLink>
          <NavLink to="/services" className="baabco-navbar__nav__link">
            Services
          </NavLink>
          <NavLink to="/case-studies" className="baabco-navbar__nav__link">
            Case Studies
          </NavLink>
          <NavLink to="/testimonials" className="baabco-navbar__nav__link">
            Testimonials
          </NavLink>
          <NavLink to="/resources" className="baabco-navbar__nav__link">
            Resources
          </NavLink>
          <NavLink to="/about" className="baabco-navbar__nav__link">
            About
          </NavLink>
          <NavLinkButton
            to="/contact"
            className="baabco-navbar__nav__link baabco-navbar__nav__link--button"
          >
            Contact
          </NavLinkButton>
        </nav>

        <button
          css={{
            background: 'transparent',
            border: 'none',
            width: 20,
            height: 15,
            position: 'relative',
            transform: 'rotate(0deg)',
            transition: '.5s ease-in-out',
            cursor: 'pointer',
            zIndex: 1000,
            [`@media (min-width: ${BREAKPOINT + 1}px)`]: {
              display: 'none',
            },
          }}
          id="mobile-menu-toggle"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <HamburgerMenuBar isOpen={isOpen} />
          <HamburgerMenuBar isOpen={isOpen} />
          <HamburgerMenuBar isOpen={isOpen} />
          <HamburgerMenuBar isOpen={isOpen} />
        </button>
      </div>
    </header>
  );
};

export default Header;
