import React from 'react';
import { Link } from 'gatsby';

import PageContent from '../components/page-content';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import heroImage from '../images/hero.png';

import MaxwellLockup from '../components/maxwell-lockup';

const IndexPage = () => (
  <Layout>
    <SEO title="Professional development services" />
    <PageContent>
      <MaxwellLockup css={{ marginLeft: '0' }} />
      <div
        css={{
          minHeight: 350,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
          '@media (min-width: 768px)': {
            flexDirection: 'row',
            minHeight: 380,
            maxHeight: 1000,
          },
        }}
      >
        <div
          css={{
            maxWidth: 550,
          }}
        >
          <h1
            css={{
              fontSize: '1.2rem',
              fontWeight: '700',
              marginBottom: '1rem',
              '@media (min-width: 768px)': {
                fontSize: '1.5rem',
              },
            }}
          >
            Professional Development services to support Senior Leadership in reaching and
            exceeding their organizational vision
          </h1>
          <p css={{ fontSize: '0.9rem', marginBottom: '1rem' }}>
            In order for senior leadership to reach and exceed their organizational vision and
            goals, BAABCO Consulting Services provides professional development which builds and
            strengthens on the essential pillars of Accountability, Effectiveness, Communication,
            Mentoring, Influence and Loyalty. We provide these services in the sectors of
            Corporate, Education and Government.
          </p>
          <Link
            css={{
              backgroundColor: '#4F9CF2',
              borderRadius: 50,
              padding: '8px 20px',
              color: '#FFF',
              boxShadow: '0 4px 10px 0 rgba(black, 0.1)',
              textDecoration: 'none',
              fontSize: '0.9rem',
              ':hover': {
                backgroundColor: '#4F9CF2',
                color: '#FFF',
              },
            }}
            to="/services/"
          >
            Our Services &raquo;
          </Link>
        </div>
        <div
          css={{
            display: 'none',
            '@media (min-width: 768px)': {
              paddingRight: 80,
              maxWidth: 290,
              display: 'block',
            },
          }}
        >
          <img css={{ maxWidth: '100%' }} src={heroImage} alt="BAABCO Consulting Services" />
        </div>
      </div>
      <div css={{ textAlign: 'center' }}>
        <h2 css={{ fontWeight: 700, marginBottom: '1rem', fontSize: 22 }}>Our Core Values</h2>
        <p>
          Accountability &bull; Effectiveness &bull; Communication &bull; Mentoring &bull;
          Influence &bull; Loyalty
        </p>
      </div>
    </PageContent>
  </Layout>
);

export default IndexPage;
