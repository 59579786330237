import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const TestimonialContent = ({ children, ...rest }) => (
  <div
    css={{
      marginBottom: 8,
      fontSize: '1.2rem',
      fontWeight: 'bold',
      position: 'relative',

      ':before': {
        position: 'absolute',
        fontSize: '2.5rem',
        color: '#417BBC',

        content: "' '",
        top: '-1rem',
        left: '-1.5rem',
      },
      ':after': {
        position: 'absolute',
        fontSize: '2.5rem',
        color: '#417BBC',

        content: "' '",
        top: '-1rem',
        right: '-1.5rem',
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

TestimonialContent.propTypes = {
  children: node.isRequired,
};

export default TestimonialContent;
