import React from 'react';
import PropTypes from 'prop-types';

const { func, oneOfType, string } = PropTypes;

const FormControl = ({ is, ...rest }) => {
  const Element = is;

  return (
    <Element
      css={{
        display: 'block',
        width: '100%',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      }}
      {...rest}
    />
  );
};

FormControl.propTypes = {
  is: oneOfType([func, string]).isRequired,
};

export default FormControl;
