import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const LeadershipAndInfluencePage = () => (
  <Layout>
    <SEO title="Leadership and Influence" />
    <PageContent>
      <PageContentTitle>Leadership and Influence</PageContentTitle>
      <div
        css={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p>
          Leader,
          <br />
          There is a distinct and recognizable profile of those with Influence.
          <br />
          It is said, in order to earn preferred Influence, one must gain a positive reputation and
          the ability to successfully guide, while caring for the well being of those they serve.
          <br />
          Additionally, Influence is a far better asset in life and business than Authority.
          <br />
          Perhaps you can recall the first and most positive Influencers in your life:
          <br />
          A teacher, parent, grand parent, friend, pastor, spouse, manager, mentor or coach.
          <br />
          In either case, the individual was or is likely successful in helping you to develop a
          better vision of and for yourself. If you are fortunate, that individual is still being
          of Influence for you today and witnessing the benefits it has for you and those you now
          serve.
          <br />
          Ask Yourself:
        </p>
        <ul>
          <li>
            1. How does my Influence support and drive the ability of my team to be as Effective as
            they can be?
          </li>
          <li>
            2. As their leader, how does my Influence positively contribute to my direct reports
            over their requirement to simply be compliant?
          </li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default LeadershipAndInfluencePage;
