import React from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/react';
import emotionReset from 'emotion-reset';
import Helmet from 'react-helmet';

import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:400,400i,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Global
        styles={css`
          ${emotionReset}
        `}
      />
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }

          html {
            background: #f1f1f4;
            font-family: sans-serif;
            line-height: 1.15;
          }

          body {
            margin: 0;
            font-family: 'Lato', sans-serif;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #142037;
            text-align: left;
            background-color: #f1f1f4;
          }

          p {
            margin-bottom: 15px;
          }

          em {
            font-style: italic;
          }

          ul {
            list-style: disc;
            margin-left: 30px;
          }

          sup {
            vertical-align: super;
            font-size: smaller;
          }
        `}
      />
      <div
        css={{
          background: '#FFF',
          padding: 15,
          '@media (min-width: 768px)': {
            padding: 30,
          },
          '@media (min-width: 1170px)': {
            maxWidth: 1070,
            margin: '20px auto',
            padding: 60,
            boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
