import React from 'react';

import PageContent from '../components/page-content';
import PageContentTitle from '../components/page-content-title';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import PageContentColumn from '../components/page-content-column';
import MaxwellLockup from '../components/maxwell-lockup';

import aliciaPhoto from '../images/alicia.png';
import burtPhoto from '../images/burt.png';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <PageContent>
      <MaxwellLockup />
      <PageContentTitle>About Us</PageContentTitle>
      <div css={{ textAlign: 'center', marginBottom: 32 }}>
        <h2 css={{ fontWeight: 700, fontSize: 22, marginBottom: 15 }}>Mission Statement</h2>
        <p>
          To collaborate with our clients, raise expectations and improve upon present results.
        </p>
      </div>
      <div
        css={{
          a: {
            color: '#4F9CF2',
          },
          h2: {
            fontWeight: 700,
            fontSize: 22,
            marginBottom: 15,
          },
          '@media (min-width: 768px)': {
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
      >
        <PageContentColumn>
          <div
            css={{
              maxWidth: 200,
              marginBottom: 40,
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <img css={{ maxWidth: '100%' }} src={aliciaPhoto} alt="Alicia Villarreal" />
          </div>
          <h2>Dr. Alicia Villarreal</h2>
          <p>
            As a PhD in Interdisciplinary Learning and Teaching, I am privileged to have the
            opportunity of positively impacting the professional lives of senior leadership and the
            organizations under their responsibility.
          </p>

          <p>
            In order to help our clients succeed at multiple performance measurements, BAABCO
            Consulting Services strategies vary by each coaching project based on the desired
            results for improvements. These client sectors include Corporate America, Education and
            Government.
          </p>

          <p>
            Within every client relationship, we support and respectfully challenge leadership to
            reach and exceed their vision of a culture which embraces their specific core values,
            simultaneous with incorporating the necessary pillars of accountability, effectiveness,
            communication, mentoring, influence and loyalty.
          </p>

          <p>
            Specifically, at BAABCO Consulting Services, we serve at the pleasure of our esteemed
            clients in order to help them positively shift culture mindsets at all levels of the
            organization. Simply said, when leadership invests in a sustainable yet forward moving
            journey of professional development for themselves and their staff, this results in a
            workplace where all enterprise members feel better about their purpose, their work
            environment and the forward vision of the organization.
          </p>

          <p>
            In conclusion, upon an organization reaching their pivotal desired levels, the present
            challenges they encounter with collaboration, conflict resolution, turn over and
            teamwork, show noticeable improvement.
          </p>
        </PageContentColumn>
        <PageContentColumn>
          <div
            css={{
              maxWidth: 200,
              marginBottom: 40,
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <img css={{ maxWidth: '100%' }} src={burtPhoto} alt="Burt Villarreal" />
          </div>
          <h2>Burt Villarreal</h2>
          <p>
            As a successful business man residing in San Antonio Texas, Burt has served in
            membership on the elite Sales Advisory Council, a leadership role in which members are
            elected by their company peers. Additionally, he has earned a position in the
            prestigious President’s Club a multitude of times. In his early years of consulting,
            Burt was awarded Rookie of the Year and Sales Consultant of the Year. Further, his
            continued cardinal performances earned him District Manager of the Year, and Gold
            Profit Performer of the Year. Throughout his career, Burt has received a total of four
            coveted company Achievement Rings from various sales-driven organizations. He has also
            reached great heights outside of the sales world, including becoming a highly
            successful Real Estate Investor. Recently, he continued to expand his horizon of
            success and share it with many others by creating the{' '}
            <a href="https://www.askburt.biz/">
              AskBurt<sup>tm</sup> Mobile App and website, askburt.biz
            </a>{' '}
            . He has also published two books, entitled{' '}
            <a href="https://www.askburt.biz/art-of-professional-prospecting/">
              <em>The Art of Professional Prospecting</em>
            </a>{' '}
            and{' '}
            <a href="https://www.52strategicwords.com/">
              <em>The 52 Strategic Words</em>
            </a>
            . These books have become well-known, essential guides to expanding one&rsquo;s own
            professional sales and leadership careers.{' '}
            <a href="https://www.52strategicwords.com/">
              <em>The 52 Strategic Words</em>
            </a>
            , co-authored with Dr. Alicia Villarreal, is scheduled to be released in mid 2020.
          </p>

          <p>
            Burt has funded over one billion dollars in technology transactions and remains a
            leader among leaders in the very competitive financial industry. He often travels to
            share his personal sales acumen in workshops with his own published handbook,{' '}
            <a href="https://www.askburt.biz/art-of-professional-prospecting/">
              <em>The Art of Professional Prospecting</em>
            </a>
            . He is happily married to his wife of 30 years, Dr. Alicia Villarreal, and together
            they have two children as well as four grandchildren.
          </p>
        </PageContentColumn>
      </div>
    </PageContent>
  </Layout>
);

export default AboutPage;
