import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const LeadershipAndEffectivenessPage = () => (
  <Layout>
    <SEO title="Leadership and Effectiveness" />
    <PageContent>
      <PageContentTitle>Leadership and Effectiveness</PageContentTitle>
      <div
        css={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p>
          Leaders,
          <br />
          Influence, Communication, Articulation, Vision, Balance, Integrity, Reputation.
          <br />
          Enhanced skill sets to continuously build on these attributes are pivotal in order to
          command Your Effectiveness.
          <br />
          Your Effectiveness is delivered in all which you verbalize, act upon and visually convey.
          <br />
          Subsequently, Effectiveness follows you, just as your shadow follows you.
          <br />
          In case you have not experienced the proven strategic process below:
          <br />
          The Effectiveness you bring serves to develop on Abilities, Attitudes and Ambitions.
          <br />
          Ask Yourself:
        </p>
        <ul>
          <li>
            1. If my senior management randomly chose 3 of my direct reports, would they easily be
            able to acknowledge my positive Effectiveness in the last ninety days?
          </li>
          <li>
            2. What goals have I tasked to myself in order to over come any possible complacency
            and the importance of my daily Effectiveness?
          </li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default LeadershipAndEffectivenessPage;
