import React from 'react';

import PageContent from '../../components/page-content';
import PageContentTitle from '../../components/page-content-title';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const LeadershipAndMentoringPage = () => (
  <Layout>
    <SEO title="Leadership and Mentoring" />
    <PageContent>
      <PageContentTitle>Leadership and Mentoring</PageContentTitle>
      <div
        css={{
          maxWidth: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <p>
          Leader, We are firm believers in the high benefits as well as the risks, of learned
          behaviors within a people environment.
          <br />
          Respect, Integrity, Communication and Loyalty are just a few of the behaviors that can
          move the needle in one direction, or another.
          <br />
          As the closest connection of leadership to your direct reports, you own the Mentoring
          components of performance and relationship to that member...Your member.
          <br />
          A Leader might be the topic of member conversations more than they desire.
          <br />
          Perhaps, unbeknownst to You, You are a mentor to someone on your team.
          <br />
          Mentoring, You are at the helm.
          <br />
          Ask Yourself:
        </p>
        <ul>
          <li>1. What behaviors will I exemplify today to ensure supreme Mentoring?</li>
          <li>2. Who am I mentoring today with intention of grooming as my replacement?</li>
        </ul>
      </div>
    </PageContent>
  </Layout>
);

export default LeadershipAndMentoringPage;
