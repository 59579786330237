import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const FormGroup = ({ children, ...rest }) => (
  <div
    css={{
      marginBottom: 20,
      ':last-of-type': {
        textAlign: 'center',
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

FormGroup.propTypes = {
  children: node.isRequired,
};

export default FormGroup;
