import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const PageContentTitle = ({ children }) => (
  <h1
    css={{
      textAlign: 'center',
      fontSize: '1.6rem',
      marginBottom: 40,
      position: 'relative',
      paddingBottom: 10,
      fontWeight: 700,
      ':after': {
        position: 'absolute',
        content: '" "',
        bottom: 0,
        left: 'calc(50% - 25px)',
        width: 50,
        background: '#D8D8D8',
        height: 4,
        borderRadius: 4,
      },
    }}
  >
    {children}
  </h1>
);

PageContentTitle.propTypes = {
  children: node.isRequired,
};

export default PageContentTitle;
