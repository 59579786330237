import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const CaseStudy = ({ children, ...rest }) => (
  <div
    css={{
      borderBottom: '1px solid #ced4da',
      maxWidth: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 32,
      paddingBottom: 24,
      ':last-of-type': {
        borderBottom: 'none',
        paddingBottom: 0,
        marginBottom: 0,
      },
      h2: {
        fontWeight: 700,
        marginBottom: 20,
        fontSize: 22,
      },
      h3: {
        fontWeight: 700,
        fontSize: 16,
        marginBottom: 12,
      },
      ul: {
        listStyle: 'disc',
        marginLeft: 30,
        marginBottom: 15,
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

CaseStudy.propTypes = {
  children: node.isRequired,
};

export default CaseStudy;
