import React from 'react';

const Footer = () => (
  <footer
    css={{
      margin: '30px -15px -15px -15px',
      background: '#142037',
      padding: 15,
      boxShadow: '0 -10px 0 0 #417BBC, 0 -20px 0 0 #406DAE',
      '@media (min-width: 768px)': {
        margin: '60px -30px -30px -30px',
      },
      '@media (min-width: 1170px)': {
        margin: '60px -60px -60px -60px',
        padding: '40px 60px',
      },
    }}
  >
    <div css={{ color: '#9B9B9B', fontSize: '0.8rem' }}>© {new Date().getFullYear()} BAABCO</div>
  </footer>
);

export default Footer;
