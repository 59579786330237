import React from 'react';
import { node } from 'prop-types';

const ServicesPageSectionTitle = ({ children, ...rest }) => (
  <h2
    css={{
      fontSize: '1.2rem',
      marginBottom: 10,
      fontWeight: 700,
    }}
    {...rest}
  >
    {children}
  </h2>
);

ServicesPageSectionTitle.propTypes = {
  children: node.isRequired,
};

export default ServicesPageSectionTitle;
