import React from 'react';
import { Link } from 'gatsby';

import PageContent from '../components/page-content';
import PageContentTitle from '../components/page-content-title';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import MaxwellLockup from '../components/maxwell-lockup';

import ServicesPageSection from '../components/services/services-page-section';
import ServicesPageSectionTitle from '../components/services/services-page-section-title';
import ServicesPageSectionGraphic from '../components/services/services-page-section-graphic';

import allEmployeesEngageWithYourCustomers from '../images/services/all-employees-engage-with-your-customers.svg';
import pentagonLeadership from '../images/services/pentagon-leadership-series.svg';
import valueProposition from '../images/services/value-proposition.svg';
import coreValues from '../images/services/core-values.svg';
import rolePlayScenarios from '../images/services/role-play-scenarios.svg';
import leadershipMoments from '../images/services/leadership-moments.svg';
import newBusinessDevelopment from '../images/services/new-business-development.svg';
import askBurtMobileApp from '../images/publications/askburt-mobile-app.png';
import smartGoalSetting from '../images/services/smart-goal-setting.svg';
import leadershipToGrowLeaders from '../images/services/leadership-grow-leaders.svg';
import essentialDialogues from '../images/services/essential-dialogues.svg';
import employeeSexualHarassment from '../images/services/employee-sexual-harassment.svg';
import communicationEmailProtocol from '../images/services/communication-email-protocol.svg';

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <PageContent>
      <MaxwellLockup />
      <PageContentTitle>Services</PageContentTitle>
      <ServicesPageSection>
        <ServicesPageSectionGraphic
          src={pentagonLeadership}
          alt="The Pentagon Leadership Series"
        />
        <div>
          <ServicesPageSectionTitle id="pentagon-leadership-series">
            The Pentagon Leadership Series
          </ServicesPageSectionTitle>
          <p>
            Too often, high performers are promoted but without the necessary professional
            development, in order to lead sustainably and successfully. The Pentagon Leadership
            Series focuses on the 5 essentials of Accountability, Effectiveness, Mentorship,
            Influence and Loyalty. Additionally, the importance of timely and proper communication
            is threaded throughout the entire series. Once each manager group session is completed,
            we follow through with one on one coaching for each manager in order to tailor the
            components to their particular style, need and vision.
          </p>
          <ul css={{ listStyle: 'disc', marginLeft: 30 }}>
            <li>
              <Link to="/publications/leadership-and-accountability">
                Leadership and Accountability
              </Link>
            </li>
            <li>
              <Link to="/publications/leadership-and-effectiveness">
                Leadership and Effectiveness
              </Link>
            </li>
            <li>
              <Link to="/publications/leadership-and-communication">
                Leadership and Communication
              </Link>
            </li>
            <li>
              <Link to="/publications/leadership-and-mentoring">Leadership and Mentoring</Link>
            </li>
            <li>
              <Link to="/publications/leadership-and-influence">Leadership and Influence</Link>
            </li>
            <li>
              <Link to="/publications/leadership-and-loyalty">Leadership and Loyalty</Link>
            </li>
          </ul>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={valueProposition} alt="Your Company Value Proposition" />
        <div>
          <ServicesPageSectionTitle id="your-company-value-proposition">
            Your Company Value Proposition
          </ServicesPageSectionTitle>
          <p>
            This is a grass roots exercise. We focus on the development within your leader&rsquo;s
            department as to the Why, What and How of their products and service offerings. They
            are guided in framing the emotional motivators which build the team&rsquo;s desire that
            delivers top rated engagement and customer service. The completed Value Proposition
            further drives the message that your customers and clients will care more when they
            realize how much you care and not just how much you know. During the process, we make
            certain that each component of their Value Proposition incorporates the strategic
            alignment to your company&rsquo;s core values.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic
          src={allEmployeesEngageWithYourCustomers}
          alt="All Employees Engage with Your Customers"
        />
        <div>
          <ServicesPageSectionTitle id="employees-customer-engagement">
            All Employees Engage with Your Customers
          </ServicesPageSectionTitle>
          <p>
            One thing is for certain...every employee, regardless of title, engages with your
            customer or prospect at one point or another during the business relationship.
            Engagement will be visible, written, verbal, non verbal, within your physical business
            location or off site. If these avenues of engagement are not prioritized, relational
            risk is increased, thus displacing the coveted trust which is essential to a successful
            business relationship. In order to help prevent an undesired perception, BAABCO
            provides a four hour educational and fun workshop. We introduce and collaborate on
            important topics which include, Delivering Excellent Customer Service Results, All are
            Responsible for Our Professional Image and, Developing Sustainable Trust and
            Transparency. Additionally, we incorporate interactive role play scenarios, team
            activities, pre and post surveys to measure the day’s learning, results and if needed,
            action plan.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={coreValues} alt="Core Values" />
        <div>
          <ServicesPageSectionTitle id="core-values">Core Values</ServicesPageSectionTitle>
          <p>
            Because the highest of sustainable success is driven in part, by the unshakable core
            values which guide the necessary principles dictating our behaviors and actions...we
            incorporate this professional development at every level. The Core Values of your firm
            do connect at every level. Therefore we instill with management that while Core Values
            are driven from the top, the conviction of these Core Values must be trusted and
            embraced at every level...No exceptions. As an example, we cover the importance of
            Integrity. Integrity is a Core Value not only important to the organization but is an
            essential core foundation of who a person is, both professionally and personally.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={rolePlayScenarios} alt="Role Play Scenarios" />
        <div>
          <ServicesPageSectionTitle id="role-play-scenarios">
            Role Play Scenarios
          </ServicesPageSectionTitle>
          <p>
            Yes, role playing is often challenging and awkward, even for the most experienced of
            leaders. When we enter in a consultative support role with your firm, strategic and
            true situational role play sessions become highly relevant to the growth process we are
            hired to support. These role play scenarios serve to positively impact the
            effectiveness of your leaders and team members in the critical and daily engagement
            that occur with internal and external customers. This is often referred to as a “dress
            rehearsal” on critical topics. They prepare your leaders in immediate readiness to
            address crucial situations and engagements which can quickly derail, if not addressed
            properly and proactively.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={leadershipMoments} alt="Leadership Moments" />
        <div>
          <ServicesPageSectionTitle id="leadership-moments">
            Leadership Moments
          </ServicesPageSectionTitle>
          <p>
            Everything possible is done to Influence Leadership when we are physically there and
            not there. Directly from our most current book,{' '}
            <a href="https://www.52strategicwords.com/">
              <em>The 52 Strategic Words</em>
            </a>
            , we stay in communication with leadership on to-the-point strategies which focus on
            each of the 52 words. Additionally, each leader will independently select 3 of the 52
            words as a starting point for BAABCO Consulting Services to merge into their specific
            professional development. As each leader exhibits professional growth, we periodically
            review{' '}
            <a href="https://www.52strategicwords.com/">
              <em>The 52 Strategic Words</em>
            </a>{' '}
            intentionally with a forward path to fueling new growth and measurements.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={newBusinessDevelopment} alt="New Business Development" />
        <div>
          <ServicesPageSectionTitle id="new-business-development">
            New Business Development
          </ServicesPageSectionTitle>
          <p>
            Too often, firms set out to hire closers. Sadly, they neglect to bring in Hired Guns!
            As written in our book,{' '}
            <a href="https://www.askburt.biz/art-of-professional-prospecting/">
              <em>The Art of Professional Prospecting</em>
            </a>
            , a Hired Gun comes to town, eliminates the competition and TAKES OVER! This strategy
            must start with the HUNT (Daily Net New Prospecting). We introduce your sales teams to
            Burt&rsquo;s 10 by 10 daily net new prospecting formula. This is a &ldquo;no wiggle
            room&rdquo; process that will quickly build their 30 day pipeline and build on
            Burt&rsquo;s 20/30 rule. The 20/30 rule is the most significant habit to live by for
            successful, consistent and profitable sales. If your compensation program enables a
            Sales Warrior to earn a 6 figure income, then the 20/30 rule will become pivotal to
            that attainable achievement.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={askBurtMobileApp} alt="AskBurt Mobile Sales App" />
        <div>
          <ServicesPageSectionTitle id="askburt-sales-app">
            AskBurt<sup>tm</sup> Mobile Sales App
          </ServicesPageSectionTitle>
          <p>
            As Burt&rsquo;s 10 by 10 helps your Sales Warriors to build a solid, consistent and
            profitable 30 day pipeline, we then support you with the necessary skill sets to win
            the sale. The AskBurt<sup>tm</sup> Mobile Sales App contains 14 paramount components
            with 160 sales questions and to the point answers in order to prepare your Sales
            Warrior for their next appointment. We cover everything from prospecting, qualifying,
            relationship building, closing and everything in between! Additionally, the app
            includes strategic one minute videos, trackers, question submissions, BURTISMS and the
            electronic versions of our books,{' '}
            <a href="https://www.askburt.biz/art-of-professional-prospecting/">
              <em>The Art of Professional Prospecting</em>
            </a>{' '}
            and{' '}
            <a href="https://www.52strategicwords.com/">
              <em>The 52 Strategic Words</em>
            </a>
            .
          </p>
          <p>
            <a href="https://www.askburt.biz/art-of-professional-prospecting/">
              <em>The Art of Professional Prospecting</em>
            </a>{' '}
            and the AskBurt<sup>tm</sup> Mobile Sales App are delivered in a one day sales training
            session with your Sales Management and Sales Warriors.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={smartGoalSetting} alt="SMART Goal Setting" />
        <div>
          <ServicesPageSectionTitle id="smart-goal-setting">
            SMART Goal Setting
          </ServicesPageSectionTitle>
          <p>Specific, Measurable, Achievable, Relevant, Time-Bound</p>
          <p>
            BAABCO Consulting Service aligns this call to action exercise with clients in order to
            drive Desired Results.
          </p>
          <p>
            We begin by assessing our client&rsquo;s must-haves within the alignment of their
            vision. This is followed with purposeful and interactive sessions which then drive
            collaboration between business lines and senior leadership. In the end, the vision to
            desired results are clear and concise in every direction which touches the internal and
            external customers of your organization.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic
          src={leadershipToGrowLeaders}
          alt="Leadership to Grow Leaders"
        />
        <div>
          <ServicesPageSectionTitle id="leadership-to-grow-leaders">
            Leadership to Grow Leaders
          </ServicesPageSectionTitle>
          <p>
            As is expected, many top performers are promoted to leadership positions in which
            successfully developing a team is anticipated and required. The leadership position
            will always demand the influencing of a team to reach it’s essential goals of
            accountability, effectiveness and productivity. Leadership to Grow Leaders is a
            transformation resource which drives forward movement through the trajectory of growth.
            This growth is fortified through professional development which first identifies the
            current stage of the leader’s horizontal level of relational competence in management.
            Together, and within the visionary alignments of the senior leaders, we then take
            action with intentional focus. This is accomplished with each department manager via a
            series of key-sustainable practices including analyzing, cultivating, and building
            positive momentum as they successfully develop their team.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic src={essentialDialogues} alt="Essential Dialogues" />
        <div>
          <ServicesPageSectionTitle id="essential-dialogues">
            Essential Dialogues
          </ServicesPageSectionTitle>
          <p>
            This professional development addresses the WHY and HOW to approach conversations. This
            course helps to improve communication across the organization and engages all to
            participate in the shared vision. Specifically with those of heart, opposing opinions,
            strong emotions and where the stakes are high. We will discuss how to approach and
            master the art of crucial conversations. This is accomplished in part by learning to
            look for non-verbal signs often relayed by our body or facial expressions. Ultimately,
            a preferred safe environment is created that nurtures dialogue, which establishes a
            forward moving path towards action and optimum results.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic
          src={employeeSexualHarassment}
          alt="Employee/Sexual Harassment"
        />
        <div>
          <ServicesPageSectionTitle>Employee/Sexual Harassment</ServicesPageSectionTitle>
          <p>
            In today&rsquo;s world, knowing how to prevent occurrences of sexual harassment and
            discriminatory behavior is critical. In this Sexual Harassment Awareness training
            course your employees will learn how to apply the important skills necessary to
            identify and address sexual harassment issues and complaints. The session includes a
            detailed overview of what sexual harassment is, discusses sexual harassment prevention,
            and shows how to handle sexual harassment complaints and maintain a positive work
            environment.
          </p>
        </div>
      </ServicesPageSection>
      <ServicesPageSection>
        <ServicesPageSectionGraphic
          src={communicationEmailProtocol}
          alt="Communication and Email Protocol"
        />
        <div>
          <ServicesPageSectionTitle id="communication-and-email-protocol">
            Communication and Email Protocol
          </ServicesPageSectionTitle>
          <p>
            Email is a convenient and effective medium to conduct business communications.
            Therefore email etiquette is important as we develop our teams to communicate with both
            internal and external customers, and with a mindful mindset. In this course we discuss
            the dos and don&rsquo;ts of email etiquette guidelines. We provide explicit guidelines
            on how to address an email message, who to CC vs. BC, what should be on your subject
            line, the role of grammar, acronyms rules, confidentiality, and much more.
          </p>
        </div>
      </ServicesPageSection>
    </PageContent>
  </Layout>
);

export default ServicesPage;
