import React from 'react';
import PropTypes from 'prop-types';

const { node } = PropTypes;

const ServicesPageSection = ({ children }) => (
  <section
    css={{
      marginBottom: 40,
      maxWidth: 840,
      marginLeft: 'auto',
      marginRight: 'auto',
      '@media (min-width: 768px)': {
        display: 'flex',
      },
      a: {
        color: '#4F9CF2',
        textDecoration: 'none',
      },
      p: {
        marginBottom: 15,
      },
    }}
  >
    {children}
  </section>
);

ServicesPageSection.propTypes = {
  children: node.isRequired,
};

export default ServicesPageSection;
